import { useEffect, useState, type FC } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { contactUsUrl } from "~/config";
import { useToast } from "~/hooks/useToast";
import { LoadingSpinner } from "~/media/icons";
import { Input, Textarea } from "../UI/Form";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  message: string;
};

interface WindowWithAnalytics extends Window {
  dataLayer: Record<string, unknown>[];
  LO: { events: { track: (event: string) => void } };
}

const ContactForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leadInfo, setLeadInfo] = useState({ sourceChannel: "", sourceChannelId: "" });

  const { toastSuccess, toastError, ToastContainer } = useToast();

  useEffect(() => {
    const leadInfoInStorage = localStorage.getItem("leadInfo");
    if (!leadInfoInStorage) return;

    const parsed = JSON.parse(leadInfoInStorage);
    setLeadInfo({ sourceChannel: parsed.sourceChannel, sourceChannelId: parsed.sourceChannelId });
  }, []);

  const trackFormSubmission = (event: string) => {
    try {
      const windowWithAnalytics = window as unknown as WindowWithAnalytics;
      windowWithAnalytics.dataLayer.push({ event: event });
      windowWithAnalytics.LO.events.track(event);
    } catch (error) {
      console.error("Error tracking form submission:", error);
    }
  };

  const onSubmit: SubmitHandler<Record<string, string>> = async (data) => {
    if (Object.keys(errors).length) return;

    for (const field in data) {
      data[field] = data[field].trim();
    }

    setIsSubmitting(true);
    try {
      const response = await fetch(contactUsUrl, {
        method: "POST",
        body: JSON.stringify({
          ...data,
          sourceChannel: leadInfo.sourceChannel,
          sourceChannelId: leadInfo.sourceChannelId || null,
          page: window.location.href,
        }),
        headers: {
          "Access-Control-Allow-Origin": window.origin,
        },
      });

      if (response.status > 400) {
        const responseJson = await response.json();
        throw new Error(responseJson);
      }

      toastSuccess({ title: "Thank you!", text: "We'll be in touch shortly." });
      localStorage.removeItem("leadInfo");
      reset();

      // Track form submission in Google Analytics and Lucky Orange
      const event = window.location.pathname.includes("contact")
        ? "contact_form_submitted"
        : "home_form_submitted";

      trackFormSubmission(event);
    } catch (error) {
      toastError({ title: "Something went wrong!", text: "Please try again later." });
      console.error("Error submitting contact form:", error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="px-2 max-md:px-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-8 max-md:flex-col">
          <div className="flex flex-1 flex-col gap-6">
            <Input
              type="text"
              placeholder="First Name"
              {...register("firstName", { required: true, minLength: 2, maxLength: 128 })}
              error={errors.firstName}
            />
            <Input
              type="text"
              placeholder="Last Name"
              {...register("lastName", { required: true, minLength: 2, maxLength: 128 })}
              error={errors.lastName}
            />
            <Input
              type="email"
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email address",
                },
              })}
              error={errors.email}
            />
            <Input
              type="text"
              placeholder="Company"
              {...register("company", { required: true, minLength: 2, maxLength: 128 })}
              error={errors.company}
            />
          </div>
          <div className="flex w-full flex-1 max-md:min-h-48 max-md:flex-auto">
            <Textarea
              placeholder="Tell us about your project..."
              {...register("message")}
              maxLength={256}
            />
          </div>
        </div>
        <div className="flex items-center justify-between pt-8 max-md:flex-col max-md:items-start max-md:gap-10">
          <p className="text-body-small text-dark-60">
            By submitting, you agree to our{" "}
            <a href="/privacy-policy" className="text-electricBlue hover:underline" target="_blank">
              Privacy Policy.
            </a>
          </p>
          <button
            className={`submit-button button flex items-center px-9 py-5 ${isSubmitting ? "loading" : ""}`}
            disabled={Object.keys(errors).length > 0 || isSubmitting}
          >
            Submit
            <span className="submit-button__loader">
              <img src={LoadingSpinner.src} alt="" className="size-4" />
            </span>
          </button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
};

export default ContactForm;
